import { TemplateElement, html, defineElement, when } from '@webtides/element-js';
import style from './teasers-intro.css';
import '../../../icon/icon.js';

/**
 * @property {string} headline
 * @property {string} linkUrl
 * @property {string} linkText
 */
export default class TeasersIntro extends TemplateElement {
    constructor() {
        super({ styles: [style] });
    }

    properties() {
        return {
            headline: '',
            linkUrl: '',
            linkText: '',
        };
    }

    template() {
        return html`
            ${when(
                this.linkUrl && !this.linkText,
                html`
                    <a href="${this.linkUrl}" class="group text-base text-green-medium hover:text-green-light">
                        <h2 class="inline h3 mb-0 mr-8">${this.headline}</h2>
                        <mb-icon name="navigation--keyboard-arrow-right" class="inline-flex h-24 w-24"></mb-icon>
                    </a>
                `,
                html`
                    <div class="flex items-center justify-between">
                        <h2 class="h3 mb-0">${this.headline}</h2>
                        ${when(
                            this.linkUrl && this.linkText,
                            html`
                                <a href="${this.linkUrl}" class="flex items-center gap-8 text-base">
                                    <span>${this.linkText}</span>
                                    <mb-icon name="navigation--chevron-right" class="h-12 w-12"></mb-icon>
                                </a>
                            `,
                        )}
                    </div>
                `,
            )}
        `;
    }
}

defineElement('mb-teasers-intro', TeasersIntro);
